import React, { useEffect } from 'react';
import { Accordion, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import {
  provideVisualizationData,
  serializeDisplayRange,
} from '@/annotation/ckEditorPlugins/components/content.utilities';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { RangeExport } from '@/trendData/duration.store';
import { TableViewer } from '@/tableBuilder/tableViewer/TableViewer.molecule';
import { ChartSettingsInterface } from './tableViewer/ChartSettings.molecule';
import { NewLineToBr } from '@/core/NewLineToBr.atom';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { TableBuilderMode } from '@/tableBuilder/tableBuilder.constants';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { CustomToggle } from '@/core/CustomToggle.atom';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { ParametersMap } from '@/utilities/formula.constants';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import { TableBuilderSimpleAgGrid } from '@/tableBuilder/TableBuilderSimpleAgGrid.organism';
import { TableBuilderConditionAgGrid } from '@/tableBuilder/TableBuilderConditionAgGrid.organism';
import {
  AgGridProps,
  ColumnOrRowWithDefinitions,
  ColumnToThresholdsCondition,
  ColumnToThresholdsSimple,
  ConditionTableColumnsAndRows,
  ConditionTableData,
  OtherColumns,
  SimpleTableRow,
  TableBuilderHeaders,
  TableBuilderSortDirection,
  TableTextFormatter,
} from '@/tableBuilder/tableBuilder.types';
import { useDidMount } from 'rooks';
import { finishAgGridLoading } from '@/utilities/utilities';

export interface TableBuilderPropsOnlyProps extends Omit<AgGridProps, 'otherColumns'> {
  canEdit: boolean;
  isSimpleMode: boolean;
  startMode: boolean;
  isLoading: boolean;
  hasOnlyStringSeries: boolean;
  hasNumericAndStringSeries: boolean;
  hasOnlyStringMetrics: boolean;
  hasNumericAndStringMetrics: boolean;
  setCellText: (key: string, value: string, id?: string) => void;
  setHeaderText: (columnKey: string, text: string) => void;
  setColumnFilter: (key: string, filter: TableColumnFilter) => void;
  simpleTableData: SimpleTableRow[];
  conditionTableData: ConditionTableData;
  headers: TableBuilderHeaders;
  simpleColumns: ColumnOrRowWithDefinitions[];
  conditionColumns: ConditionTableColumnsAndRows;
  isTransposed: boolean;
  useSignalColorsInChart: boolean;
  isTableStriped: boolean;
  isPresentationMode: boolean;
  isViewOnlyMode: boolean;
  displayRange: RangeExport;
  timezone: {
    name: string;
  };
  displayMetricOnTrend: (metricId: string, formulaItemId: string, start: number, end: number, event: any) => void;
  columnToThresholdsForCondition: ColumnToThresholdsCondition;
  columnToThresholdsForSimple: ColumnToThresholdsSimple;
  distinctStringValueMap: {
    [mode: string]: {
      [columnKey: string]: string[];
    };
  };
  textFormatter: TableTextFormatter;
  moveColumn: (key: string, newKey: string) => void;
  canSort: boolean;
  maxSortLevel: number;
  sortByColumn: ((key: string, direction: TableBuilderSortDirection) => void) | undefined;
  removeColumn: (key: string) => void;
  setActiveTool: (toolName: string) => void;
  fetchFailedMessage:
    | {
        message: string;
        apiMessage: string;
      }
    | undefined;
  setActiveRefElement: (element: Node | null) => void;
  showChartView: boolean;
  chartViewSettings: ChartSettingsInterface;
  fetchStringColumnValues: (columnKey: string, isStringColumn: boolean, cancellationGroup: string) => void;
  setChartViewSettings: (property: any) => void;
  onContentLoad?: () => void;
  updateContentMeasurements?: (measurements: { width?: number; height?: number }) => void;
  afterChartUpdate?: () => void;
  chartColors: ParametersMap;
  darkMode: boolean;
  otherColumns?: OtherColumns;
  hasMoreData?: boolean;
  setHideColumnHeadersWhenCopying: React.Dispatch<React.SetStateAction<boolean>>;
  tableItems: any[];
  rawConditionTableData: ConditionTableData;
  rawSimpleTableData: SimpleTableRow[];
  hideInteractiveContentActions?: boolean;
  contentResizeState?: number;
}

export const TableBuilderPropsOnly: React.FunctionComponent<TableBuilderPropsOnlyProps> = (
  props: TableBuilderPropsOnlyProps,
) => {
  const {
    isSimpleMode,
    startMode,
    canEdit,
    simpleTableData,
    conditionTableData,
    isPresentationMode,
    isTransposed,
    useSignalColorsInChart,
    isTableStriped,
    columnToThresholdsForCondition,
    columnToThresholdsForSimple,
    distinctStringValueMap,
    conditionColumns,
    simpleColumns,
    fetchFailedMessage,
    setActiveRefElement,
    setActiveTool,
    showChartView,
    chartViewSettings,
    onContentLoad,
    updateContentMeasurements,
    afterChartUpdate,
    chartColors,
    onAgGridReady,
  } = props;
  const { t } = useTranslation();

  useDidMount(() => {
    onContentLoad?.();
  });

  const hasFetchError = !_.isEmpty(fetchFailedMessage?.message);

  // If there is an error ag-grid won't render, so fake that it finished to ensure the screenshot finishes (CRAB-41739)
  useEffect(() => {
    if (hasFetchError || startMode) {
      finishAgGridLoading();
      onAgGridReady?.();
    }
  }, [onAgGridReady, hasFetchError, startMode]);

  if (headlessRenderMode() && !startMode) {
    provideVisualizationData({ ...props, visualization: Visualization.TABLE }, { displayRange: serializeDisplayRange });
  }

  // Ensures alert is not centered in headless mode, because otherwise renderer only captures empty space
  const alertWrapper = (alert: React.ReactNode) => (
    <div className={headlessRenderMode() ? '' : 'flexRowContainer flexCenter flexFill'}>{alert}</div>
  );

  const statColumnIsSelected = () =>
    _.chain(simpleColumns)
      .some((col) => col.key.startsWith('statistic') || col.key === COLUMNS_AND_STATS.metricValue.key)
      .value();

  const showChart = showChartView && statColumnIsSelected();

  return (
    <>
      <div id="tableBuilder" className={`flexRowContainer flexFill${isPresentationMode ? '' : ' p20'}`}>
        {((!statColumnIsSelected() && showChartView) || _.isEmpty(simpleColumns)) && !startMode && isSimpleMode && (
          <div className="flexCenter flexColumnContainer flexFill">
            <Alert variant="info" className="width-400 fs16 p25 screenshotSizeToContent" transition={false}>
              <p>
                <Icon icon={isTransposed ? 'fc-add-row' : 'fc-add-column'} extraClassNames="p5" type="theme" />
                <span> {t(isTransposed ? 'TABLE_BUILDER.SELECT_ROW' : 'TABLE_BUILDER.SELECT_COLUMN')} </span>
              </p>
            </Alert>
          </div>
        )}
        {simpleColumns.length > 0 && isSimpleMode && !startMode && !hasFetchError && (
          <div className={showChart ? 'flexFill flexColumnContainer' : 'overflowYAuto'} ref={setActiveRefElement}>
            {showChart && (
              <TableViewer
                testId="tableChartViewer"
                storeSettings={chartViewSettings}
                simpleTableData={simpleTableData}
                columns={simpleColumns}
                afterChartUpdate={afterChartUpdate}
                isTransposed={isTransposed}
                useSignalColorsInChart={useSignalColorsInChart}
                chartColors={chartColors}
              />
            )}
          </div>
        )}
        {hasFetchError &&
          !startMode &&
          alertWrapper(
            <Alert variant="info" className="max-width-400 screenshotSizeToContent fs fs16" transition={false}>
              <NewLineToBr lineToBreak={fetchFailedMessage?.message ?? ''} />

              {fetchFailedMessage?.apiMessage && (
                <Accordion className="pt10 font-size-smaller">
                  <CustomToggle
                    eventKey="0"
                    Component={({ onClick, label, isCardOpen }) => (
                      <div
                        onClick={onClick}
                        data-testid="detailsToggle"
                        className="sq-text-primary cursorPointer flexColumnContainer flexAlignCenter">
                        <span className="pr5">{t(label)}</span>
                        <Icon icon={isCardOpen ? 'fa-angle-down' : 'fa-chevron-right'} />
                      </div>
                    )}
                    label="TABLE_BUILDER.VIEW_DETAILS"
                  />
                  <Accordion.Collapse eventKey="0" data-testid="apiMessage">
                    <span>{fetchFailedMessage.apiMessage}</span>
                  </Accordion.Collapse>
                </Accordion>
              )}
            </Alert>,
          )}
        {!showChartView && simpleColumns.length > 0 && isSimpleMode && !startMode && !hasFetchError && (
          <TableBuilderSimpleAgGrid
            {...props}
            isStriped={isTableStriped}
            sortByColumn={props.sortByColumn ?? _.noop}
            columnToThresholds={columnToThresholdsForSimple}
            distinctStringValueMap={distinctStringValueMap?.[TableBuilderMode.Simple]}
            otherColumns={props.otherColumns?.simple ?? {}}
            updateContentMeasurements={updateContentMeasurements}
          />
        )}
        {!isSimpleMode && !startMode && !hasFetchError && (
          <TableBuilderConditionAgGrid
            {...props}
            tableData={conditionTableData}
            columns={conditionColumns}
            isStriped={isTableStriped}
            sortByColumn={props.sortByColumn ?? _.noop}
            columnToThresholds={columnToThresholdsForCondition}
            distinctStringValueMap={distinctStringValueMap?.[TableBuilderMode.Condition]}
            otherColumns={props.otherColumns?.condition ?? {}}
          />
        )}
        {startMode &&
          alertWrapper(
            <Alert variant="info" className="width-400 fs16 p25 screenshotSizeToContent" transition={false}>
              {!canEdit && <p>{t('TABLE_BUILDER.START_MODE_NO_CONTENT')}</p>}
              {canEdit && ( // TODO CRAB-27446: <p> cannot appear as a descendant of <p>
                <p>
                  <p>{t('TABLE_BUILDER.START_MODE')}</p>
                  <a className="cursorPointer" onClick={() => setActiveTool(TREND_TOOLS.THRESHOLD_METRIC)}>
                    <Icon icon="fa-plus-circle" extraClassNames="p5" type="theme" testId="addFirstMetricButton" />
                    <span>{t('TABLE_BUILDER.ADD_METRIC')}</span>
                  </a>
                </p>
              )}
            </Alert>,
          )}
      </div>
    </>
  );
};
