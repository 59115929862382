// @ts-strict-ignore
import React, { ReactElement } from 'react';
import Highcharts from 'highcharts';
import _ from 'lodash';
import classNames from 'classnames';
import { Icon } from '@seeqdev/qomponents';
import { ImageContent } from '@/annotation/ckEditorPlugins/components/ImageContent.molecule';
import { ReactJsonContent } from '@/annotation/ckEditorPlugins/components/ReactJsonContent.molecule';
import {
  CONTENT_MODEL_ATTRIBUTES,
  ContentCallbacks,
  ContentDisplayMode,
  CustomPlugin,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { Visualization, VisualizationData } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { ChartViewActions, ChartViewData } from '@/trend/ChartView.organism';
import { XyPlotContentProps } from '@/scatterPlot/XyPlotContent.organism';
import { TableBuilderPropsOnlyProps } from '@/tableBuilder/TableBuilderPropsOnly.organism';
import { ChartViewContentProperties } from '@/reportEditor/ChartViewContent.molecule';
import { RangeExport } from '@/trendData/duration.store';
import { deserializeRange } from '@/datetime/dateTime.utilities';
import { formatCapsuleLabel } from '@/utilities/chartHelper.utilities';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { INTERACTIVE_CONTENT_WORKSTEP_VERSION } from '@/reportEditor/report.constants';
import { Editor } from '@ckeditor/ckeditor5-core';

const MINIMAP_HEIGHT = 35;

export enum SubVisualization {
  SCATTER_PLOT = 'scatterPlot',
  DENSITY_PLOT = 'densityPlot',
}

/**
 * Used for interactive content in Calendar, Chain, or Capsule Mode to transform props that had been serialized into
 * the correct form for ChartViewContent
 * @param props
 */
export const transformTrendProps = (props: ChartViewContentProperties) => {
  // Capsule items had their formatter function removed when they were serialized, so add them back
  _.forEach(props.data.items, (item) => {
    if (item.capsuleSetId && item.dataLabels) {
      item.dataLabels.formatter = formatCapsuleLabel;
    }
  });

  // chartDrawService wants capsuleDisplayItems to have their capsules attached.
  const mapOfConditionIdToCapsulesList = _.groupBy(props.data.capsules, 'isChildOf');
  _.forEach(props.data.items, (item) => {
    if (item.itemType === ITEM_TYPES.CAPSULE) {
      item.capsules = mapOfConditionIdToCapsulesList[item.capsuleSetId] ?? [];
    }
  });

  props.configuration.isInTopic = true;
  (props.data.sqDurationStoreData.displayRange as RangeExport) = deserializeRange(props.data.serializedDateRange);
  return props;
};

/**
 * Used for interactive content in Calendar, Chain, or Capsule Mode to cleanse props prior to saving them to the
 * backend. Here, we can de-duplicate data to reduce our footprint as much as possible.
 * @param props
 */
export function cleanseTrendProps(props: { data: ChartViewData }): typeof props {
  const newProps = _.cloneDeep(props);
  _.forEach(newProps.data.items, (item) => {
    // Remove data.items.samples because it duplicates data.items.data. and isn't used
    item.samples = undefined;
    // Remove data.items.capsules b/c it duplicates data.items.data and data.capsules. Will add back when deserialized.
    item.capsules = undefined;
  });
  return newProps;
}

type VisualizationContentProps = XyPlotContentProps | TableBuilderPropsOnlyProps | ChartViewContentProperties;
export const VisualizationContentPropsTransformer: {
  [key in Visualization]: (props: VisualizationContentProps) => VisualizationContentProps;
} = {
  [Visualization.XY_PLOT]: _.identity,
  [Visualization.TABLE]: _.identity,
  [Visualization.TREND]: transformTrendProps,
  [Visualization.NONE]: _.identity,
  [Visualization.PLUGIN]: _.identity,
};

export const VisualizationContentProps: { [key in Visualization]: any } = {
  [Visualization.XY_PLOT]: {
    canShowPlot: () => true,
    fetchScatterPlot: _.noop,
    clearPointerValues: _.noop,
    setSelectedRegion: _.noop,
    zoomToRegion: _.noop,
    createAxisControl: _.noop,
    setSelectors: _.noop,
    rangeEditingEnabled: false,
    autoUpdateDisabled: true,
  },
  [Visualization.TREND]: {
    actions: {
      clearPointerValues: _.noop,
      setPointerValues: _.noop,
      selectItems: _.noop,
      updateDisplayRangeTimes: _.noop,
      setYExtremes: _.noop,
      setSelectedRegion: _.noop,
      setCapsuleTimeOffsets: _.noop,
      findCapsuleItem: _.noop,
    } as ChartViewActions,
  },
  [Visualization.TABLE]: {
    displayMetricOnTrend: () => {},
  },
  [Visualization.NONE]: {},
  [Visualization.PLUGIN]: {},
};

export interface ContentVisualizationProps {
  src: string;
  contentId: string;
  noMargin?: boolean;
  border?: boolean;
  style: any;
  target: any;
  displayMode: ContentDisplayMode;
  loading: boolean;
  onLoad: () => void;
  onError: (error: string, errorCode: number) => void;
  onMeasurementsReady: (height, width) => void;
  showMenu?: () => void;
  extraClassNames: string;
  errorClass?: string;
  error?: string;
  propertyOverrides?: any;
  isInView: boolean;
  height?: number;
  width?: number;
  darkMode?: boolean;
  fromDashboard?: boolean;
  updateContentMeasurements: (measurements: { width?: number; height?: number }) => void;
  editorId: string;
}

export function renderContentVisualization(props: ContentVisualizationProps, react: boolean) {
  return react ? <ReactJsonContent {...props} /> : <ImageContent {...props} />;
}

/**
 * Sends the data for a component to the renderer. The renderer then serializes the data to JSON and stores it on
 * the backend for use as interactive content. Because it is serialized to JSON all functions are removed and an
 * error is thrown if there are any values that are not primitives (i.e. a moment object).
 * @param data - The data to store. Usually all the props of the component.
 * @param transformers - A map of keys and associated functions to transform the data. Useful for cleansing.
 */
export function provideVisualizationData(
  data: VisualizationData,
  transformers: Record<string, (value: any) => any> = {},
) {
  const cleansedData = _.chain(data)
    .omitBy(_.isFunction)
    .mapValues((value, key) => (transformers[key] ? transformers[key](value) : value))
    .value();
  cleansedData.version = INTERACTIVE_CONTENT_WORKSTEP_VERSION;
  window.seeqGetVisualizationData = () => Promise.resolve(cleansedData);
}

export function serializeDisplayRange({ start, end, duration }): {
  start: number;
  end: number;
  duration: number;
} {
  return {
    start: start.valueOf(),
    end: end.valueOf(),
    duration: duration.valueOf(),
  };
}

export function getPropertyForContent(
  key: string,
  propertyOverrides: Record<string, any>,
  properties: Record<string, any>,
): any {
  return propertyOverrides?.[key] ?? properties[key];
}

export function setContentPropertyOverrides(editor: Editor, contentId: string, newProps: any) {
  const contentCallbacks = (editor.config.get(CustomPlugin.Content) as any).contentCallbacks as ContentCallbacks;
  const model = contentCallbacks.getCurrentModel(contentId);

  editor.model.change((writer) =>
    writer.setAttribute(
      CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES,
      _.merge({}, model.getAttribute(CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES) ?? {}, newProps),
      model,
    ),
  );
}

export function clearContentPropertyOverrides(editor: Editor, contentId: string) {
  const contentCallbacks = (editor.config.get(CustomPlugin.Content) as any).contentCallbacks as ContentCallbacks;
  const model = contentCallbacks.getCurrentModel(contentId);
  if (model) {
    editor.model.change((writer) => writer.setAttribute(CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES, undefined, model));
  }
}

/**
 * A helper to resize the chart associated with a given content ID. This is needed for when an interactive chart
 * changes its size, such as from the user switching the size from Medium to Small. Assumes that the chart is wrapped
 * in a single wrapper element and the other elements in the react wrapper (such as display range) are siblings of that
 * chart wrapper.
 */
export function refreshHighchartsSize(
  contentId: string,
  height: number,
  visualization: Visualization,
  subVisualization?: SubVisualization,
) {
  const seeqContent = document.querySelector(`[data-seeq-content="${contentId}"] [data-highcharts-chart]`);
  const maybeChartId = seeqContent?.getAttribute('data-highcharts-chart');
  const highchartsId = maybeChartId ? _.toNumber(maybeChartId) : undefined;
  if (_.isNumber(highchartsId) && Highcharts.charts[highchartsId]) {
    const chartWrapper = seeqContent.parentElement;
    const siblingsHeight = _.chain(chartWrapper.parentElement.children)
      .reject((element) => element === chartWrapper)
      .reduce((total: number, element: HTMLElement) => {
        const elementStyle = window.getComputedStyle(element);
        const marginTop = parseFloat(elementStyle.getPropertyValue('margin-top'));
        const marginBottom = parseFloat(elementStyle.getPropertyValue('margin-bottom'));
        return total + element.offsetHeight + marginTop + marginBottom;
      }, 0)
      .value();
    const chartHeight = height - siblingsHeight;
    Highcharts.charts[highchartsId].setSize(null, chartHeight);

    const minimapChartId = highchartsId + 1;
    if (
      visualization === Visualization.XY_PLOT &&
      subVisualization === SubVisualization.SCATTER_PLOT &&
      Highcharts.charts[minimapChartId]
    ) {
      Highcharts.charts[minimapChartId].setSize(null, MINIMAP_HEIGHT);
    }
  }
}

/**
 * A helper function that creates a border trigger on the top right corner of a seeq component.
 * If more than one single borderTrigger is added on the same component, we should use the
 * offsetX classes for offsetting the position based on how many previous components are there.
 *
 * Classes this component supports:
 * offset1, offset2, offset3 -> offsets the position based on previous number of border triggers
 * alwaysVisible -> in some cases in viewMode we'd want to have this trigger always visible. This
 *                  class will forder the trigger to always be visible and move it inside the
 *                  iframe so it doesn't show over any possible outside text
 *
 * @param callback Click callback
 * @param classes Specific classes to add
 * @param icon Icon to use
 * @param testId TestID to add to component
 * @param ref If needed a React ref
 * @returns DIV with the specific icon that is used for the border trigger
 */
export function renderBorderCommand(
  callback: () => void,
  classes: any,
  icon: string,
  testId: string,
  ref: React.MutableRefObject<any> = null,
): ReactElement {
  return (
    <div className={classNames('borderTrigger', classes)} data-testid={testId} ref={ref} onClick={callback}>
      <Icon icon={icon} />
    </div>
  );
}

/**
 * Returns true if the user clicks on interactive content and the underlying component should handle the click
 * instead of the editor.
 * @param htmlElement - the element to check
 * @return true if the element is interactive content and should handle the click
 */
export function callIfInteractiveContentHandlesEvent(htmlElement: HTMLElement): boolean {
  // Move column icon for ag-grid
  if (htmlElement.nodeName === 'I' && htmlElement.classList.contains('fa-arrows')) {
    return true;
  }

  if (
    htmlElement.nodeName === 'SPAN' &&
    (htmlElement.classList.contains('ag-icon-tree-open') || htmlElement.classList.contains('ag-icon-tree-closed'))
  ) {
    return true;
  }

  // Column groups for ag-grid
  if (htmlElement.closest('span.ag-column-drop-cell')) {
    return true;
  }

  return false;
}
